<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Segnalazione" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Codice IcdIX</label>
          <span class="sa-data">{{ jsonData.codiceIcdix }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Malattia infettiva</label>
          <span class="sa-data">{{ jsonData.malattiaSegnalata }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">UOSD di diagnosi</label>
          <span class="sa-data">{{ jsonData.uosdDiagnosi }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Segnalazione</label>
          <span class="sa-data">{{ formatDate(jsonData.dataSegnalazione) }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nome Medico</label>
          <span class="sa-data">{{ jsonData.nomeMedicoSegnalante }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cognome Medico</label>
          <span class="sa-data">{{ jsonData.cognomeMedicoSegnalante }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Contatti Medico</label>
          <span class="sa-data">{{ jsonData.contattoMedicoSegnalante }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Codice Fiscale/STP/ENI</label>
          <span class="sa-data">{{ jsonData.pazienteBean.identificativo }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Nascita</label>
          <span class="sa-data">{{ formatDate(jsonData.pazienteBean.dataNascita) }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cognome</label>
          <span class="sa-data">{{ jsonData.pazienteBean.cognome }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nome</label>
          <span class="sa-data">{{ jsonData.pazienteBean.nome }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sesso</label>
          <span class="sa-data">{{ jsonData.pazienteBean.sesso }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Contatto Telefonico</label>
          <span class="sa-data">{{ jsonData.pazienteBean.telefono }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Professione</label>
          <span class="sa-data">{{ jsonData.professione }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Provincia Nascita</label>
          <span class="sa-data">{{ jsonData.pazienteBean.provinciaNascita }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Comune Nascita </label>
          <span class="sa-data">{{ jsonData.pazienteBean.comuneNascita }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Email</label>
          <span class="sa-data">{{ jsonData.pazienteBean.email }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Residenza" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Provincia</label>
          <span class="sa-data">{{ jsonData.provinciaResidenza }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Comune</label>
          <span class="sa-data">{{ jsonData.comuneResidenza }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Indirizzo</label>
          <span class="sa-data">{{ jsonData.indirizzoResidenza }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Domicilio" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Provincia</label>
          <span class="sa-data">{{ jsonData.provinciaDomicilio }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Comune</label>
          <span class="sa-data">{{ jsonData.comuneDomicilio }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Indirizzo</label>
          <span class="sa-data">{{ jsonData.indirizzoDomicilio }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Segnalazione Informazioni" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Inizio Sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.dataInizioSintomi) }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Provincia Inizio Sintomi</label>
          <span class="sa-data">{{ jsonData.provinciaInizioSintomi }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Comune Inizio Sintomi</label>
          <span class="sa-data">{{ jsonData.comuneInizioSintomi }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Sintomi</label>
          <span class="sa-data">{{ jsonData.descrizioneSintomi }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ricovero in luogo di cura</label>
          <span class="sa-data">{{ jsonData.ricoveroLuogoCura }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vaccino ( se esiste )</label>
          <span class="sa-data">{{ jsonData.vaccino }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Dosi</label>
          <span class="sa-data">{{ jsonData.numeroDosi }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data ultima dose</label>
          <span class="sa-data">{{ formatDate(jsonData.dataUltimaDose) }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo di Vaccino</label>
          <span class="sa-data">{{ jsonData.tipoVaccino }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Stati Malattia" header-tag="header" footer-tag="footer" title="">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="xl" striped hover :items="jsonData.malattieInfettiveSegnalazioneStatoMalattiaBeans" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <template v-slot:cell(statoAttuale)="{ item }">
            <a>{{ item.statoAttuale }}</a>
          </template>
        </b-table>
      </div>
    </b-card>
    <b-card class="sa-card" header="Protocollo" header-tag="header" footer-tag="footer" title="">
      <protocollo-list-component :idDocumento="jsonData.id"></protocollo-list-component>
    </b-card>
  </div>
</template>
<script>
import UtilityMixin from "../../utility/UtilityMixin";
import ProtocolloListComponent from "../../protocollo/components/ProtocolloListComponent.vue";
export default {
  components: { ProtocolloListComponent },
  props: {
    jsonData: {
      Type: Object,
      default: function () {
        return {
          provinciaInizioSintomi: "",
          professione: "",
          dataInizioSintomi: null,
          comuneSintomiCodiceIstat: null,
          vaccino: null,
          numeroDosi: 0,
          dataUltimaDose: null,
          tipoVaccino: "",
          nomeMedicoSegnalante: "",
          cognomeMedicoSegnalante: "",
          contattoMedicoSegnalante: "",
          dataSegnalazione: null,
          ricoveroLuogoCura: null,
          comuneResidenzaCodiceIstat: null,
          indirizzoResidenza: "",
          comuneDomicilioCodiceIstat: null,
          indirizzoDomicilio: "",
          statoAttuale: null,
          statoAttualeDataOra: null,
          uosdDiagnosi: null,
          sesso: null,
          pazienteBean: {
            identificativo: "",
            cognome: "",
            nome: "",
            dataNascita: null,
            comuneNascitaCodiceIstat: null,
            comuneResidenzaCodiceIstat: null,
            comuneDomicilioCodiceIstat: null,
            indirizzoResidenza: "",
            indirizzoDomicilio: "",
            telefono: "",
            sesso: null,
            provinciaNascita: null,
            provinciaResidenza: null,
            provinciaDomicilio: null,
          },
          malattieInfettiveSegnalazioneStatoMalattiaBeans: [],
        };
      },
    },
  },
  mixins: [UtilityMixin],

  computed: {
    rows() {
      return this.jsonData.malattieInfettiveSegnalazioneStatoMalattiaBeans.length;
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Stato",
          key: "stato",
          sortable: true,
        },
        {
          label: "Note",
          key: "note",
          thStyle: "width: 15rem",
          sortable: true,
        },
        {
          label: "Stato Data/Ora",
          key: "statoDataOra",
          sortable: true,
          thStyle: "width: 10rem",
          formatter: function (value) {
            return UtilityMixin.methods.formatDateTime(value);
          },
        },
      ],
    };
  },
};
</script>
