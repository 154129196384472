<template>
  <sa-page-layout :btnNewVisible="btnNewVisible" :linkedit="linkedit" :btnRefreshVisible="true" @refresh="onRefresh" :showModalLoading="showModalLoading">
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Codice Fiscale</label>
            <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" :placeholder="this.$i18n.t('patients.lblFiscalCode')"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label_data">Prestazione</label>
            <b-form-select v-model="filtro.prestazione" :options="prestazioniOptions" :value="null" value-field="value" text-field="text"></b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label_data">Stato</label>
            <b-form-select v-model="filtro.stato" :options="statiOptions" :value="null" value-field="value" text-field="text"></b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label_data">Ambulatorio</label>
            <b-form-select v-model="filtro.idAmbulatorio" :options="ambulatoriOptions" :value="null" value-field="value" text-field="text"></b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label_data">Data Dal</label>
            <date-picker v-model="filtro.dataDal" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label_data">Data Al</label>
            <date-picker v-model="filtro.dataAl" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-alert v-if="!isAmbulatorioPresent" show variant="danger">ATTENZIONE: Nessuno Ambulatorio Associato a per l'utente</b-alert>
        <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(nominativo)="{ item }">
            <router-link class="sa-edit-link" :to="linkedit + '/view/' + item.id">{{ item.cognome }} {{ item.nome }}</router-link>
            <br />
            {{ item.identificativo }}
          </template>
          <template v-slot:cell(actions)="row">
            <visita-component v-if="btnVisita" :idAmbulatorio="row.item.idAmbulatorio" size="sm" :idPaziente="row.item.idPaziente" :idAccettazione="row.item.id" :statoAttuale="row.item.statoAttuale" :idEvento="row.item.idEvento"></visita-component>
            <condivisione-modal v-if="btnCondivisione" :idRisorsa="row.item.id" idSezione="accettazione"></condivisione-modal>
            <print-component :data="row" :linkPrintData="linkPrintData(row.item)" typeButton="small"></print-component>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
import CondivisioneModal from "../../../utility/components/CondivisioneModal.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import VisitaComponent from "../components/VisitaComponent.vue";
import PrintComponent from "../../../utility/components/PrintComponent.vue";
export default {
  components: { SaPageLayout, DatePicker, CondivisioneModal, VisitaComponent, PrintComponent },
  mixins: [UtilityMixin],
  computed: {
    isAmbulatorioPresent() {
      let me = this;
      let returnValue = me.ambulatoriUtente.length > 0;
      return returnValue;
    },
  },
  data() {
    return {
      rows: 0,
      perPage: 50,
      currentPage: 1,
      filter: null,
      pathDeleteAnagrafica: "/anagrafica",
      patResource: "/accettazioni",
      pathResourcePrestazioni: "/ambulatoriprestazioninomenclatore",
      pathResourceAmbulatori: "/ambulatori",
      pathResourceAmbulatoriUtenti: "/ambulatoriutenti",
      linkedit: "/accettazioneabruzzo",
      stampaReport: null,
      btnNewVisible: false,
      btnCondivisione: false,
      btnVisita: false,
      prestazioniOptions: [],
      ambulatoriOptions: [],
      ambulatoriUtente: [],
      statiOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "ACCETTATO", text: "ACCETTATO" },
        { value: "RIFIUTATO", text: "RIFIUTATO" },
        { value: "ESEGUITO", text: "ESEGUITO" },
        { value: "ABBANDONATO", text: "ABBANDONATO" },
        { value: "REFERTATO", text: "REFERTATO" },
      ],
      filtro: { codiceFiscale: null, dataDal: null, dataAl: null, stato: null, prestazione: null, idAmbulatorio: null },
      filterOn: [],
      showModalLoading: false,
      fields: [
        {
          label: this.$i18n.t("patients.lblNominative"),
          key: "nominativo",
          sortable: true,
          //thStyle: "width: 7rem",
        },
        {
          label: "Ambulatorio",
          key: "nomeAmbulatorio",
          //thStyle: "width: 5rem",
          sortable: true,
        },
        {
          label: this.$i18n.t("patients.lblTelephoneNumber"),
          key: "telefono",
          //thStyle: "width: 5rem",
          sortable: true,
        },
        {
          label: this.$i18n.t("patients.lblEmail"),
          key: "email",
          sortable: true,
        },
        {
          label: "Prestazione",
          key: "descrizionePrestazione",
          sortable: true,
        },
        {
          label: "Cod. Prestazione",
          key: "codicePrestazione",
          sortable: true,
        },
        {
          label: this.$i18n.t("patients.lblBirthDate"),
          key: "dataNascita",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD/MM/YYYY");
          },
        },
        {
          label: "Stato",
          key: "statoAttuale",
          sortable: true,
        },
        { key: "actions", label: "", thStyle: "width: 10rem" },
      ],
      items: [],
    };
  },
  created: function () {},
  mounted: function () {
    let me = this;
    me.loadPrestazioni();
    // me.loadAmbulatori();
    me.loadAmbulatoriUtenti();
    me.impostaPermessi();
    me.loadData();
  },
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
  },
  methods: {
    loadAmbulatoriUtenti() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceAmbulatoriUtenti;
      me.ambulatoriUtente = [];
      me.ambulatoriOptions = [];
      me.ambulatoriOptions = [{ value: null, text: "-Seleziona Valore-" }];
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.ambulatoriOptions.push({ value: element.id, text: element.nome });
          me.ambulatoriUtente.push(element);
        });
        // me.ambulatoriOptions.unshift({ value: null, text: "-Seleziona Valore-" });
      });
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.patResource;
      me.showModalLoading = true;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = { codiceFiscale: null, dataDal: null, dataAl: null, stato: null, prestazione: null, idAmbulatorio: null };
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onDeleteItem(item) {
      let me = this;
      let domanda = this.$i18n.t("patient.event.lblConfirmDeleteQuestion");
      let titolo = this.$i18n.t("patient.event.lblConfirmDelete");
      let si = this.$i18n.t("patient.lblYes");
      let no = this.$i18n.t("patient.lblNo");
      this.$bvModal
        .msgBoxConfirm(domanda, {
          title: titolo,
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: si,
          cancelTitle: no,
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteItem(item);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadPrestazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourcePrestazioni;
      me.prestazioniOptions = [];
      me.prestazioniOptions = [{ value: null, text: "-Seleziona Valore-" }];
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.prestazioniOptions.push({ value: element.codicePrestazione, text: element.descrizione + " (" + element.codicePrestazione + ")" });
        });
        // me.ambulatoriOptions.unshift({ value: null, text: "-Seleziona Valore-" });
      });
    },
    // loadAmbulatori() {
    //     let me = this;
    //     let link = process.env.VUE_APP_PATH_API + me.pathResourceAmbulatori;
    //     me.ambulatoriOptions = [];
    //     me.ambulatoriOptions = [{ value: null, text: "-Seleziona Valore-" }];
    //     axios.get(link).then((response) => {
    //         response.data.data.forEach((element) => {
    //             me.ambulatoriOptions.push({ value: element.id, text: element.nome });
    //         });
    //         // me.ambulatoriOptions.unshift({ value: null, text: "-Seleziona Valore-" });
    //     });
    // },
    deleteItem(item) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathDeleteAnagrafica + "/" + item.id;
      axios
        .delete(link)
        .then((Response) => {
          console.log(Response);
          me.showModalLoading = false;
          me.loadData();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onShareItem(item) {
      console.log(item);
    },
    impostaPermessi() {
      let me = this;
      me.btnNewVisible = false;
      me.btnCondivisione = false;
      me.btnVisita = false;
      me.btnNewVisible = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "accettazione", 2);
      me.btnCondivisione = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "condivisione", 2);
      me.btnVisita = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "pazienteeventi", 2);
      // console.log(me.btnCondivisione);
    },
    linkPrintData(item) {
      let returnValue = "/accettazioniprinter/" + item.id;
      return returnValue;
    },
  },
};
</script>
