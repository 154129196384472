<template>
  <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" :showModalLoading="showModalLoading" :linkedit="linkedit" @refresh="onRefresh">
    <template slot="toolbar"></template>
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <span class="sa-data">Codice Fiscale</span>
            <b-input-group class="mb-3">
              <b-form-input
                maxlength="16"
                v-model="filtro.codiceFiscale"
                type="search"
                id="codice"
                :formatter="
                  (value) => {
                    return new String(value).toUpperCase();
                  }
                "
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <span class="sa-data">Malattia Segnalata</span>
            <b-form-input v-model="filtro.malattiaSegnalata" type="search" id="malattia"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" stacked="xl" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(malattiaSegnalata)="{ item }">
            <router-link class="sa-edit-link" :to="'/malattieinfettive' + fonte + '/view/' + item.id">
              {{ item.malattiaSegnalata }}
            </router-link>
          </template>
          <template v-slot:cell(vaccino)="{ item }">
            <strong :class="getClassColorVaccino(item.vaccino)">{{ item.vaccino }}</strong>
          </template>
          <template v-slot:cell(anagrafica)="{ item }">
            <span>{{ item.identificativo }}</span>
          </template>
          <!-- <template v-slot:cell(statoAttuale)="{ item }">
                          <b-badge
                              id="popover-reactive-1"
                              :variant="getVariantBadge(item.statoAttuale)"
                              v-b-tooltip.hover="{
                                  variant: getVariantBadge(item.statoAttuale),
                                  title: formatDateTime(item.statoAttualeDataOra),
                              }"
                          >
                              {{ item.statoAttuale }}
                          </b-badge>
                      </template> -->
          <template v-slot:cell(actions)="{ item }">
            <div v-if="!item.isIndagineEpidemiologica">
              <protocollo-component v-if="item.statoProtocollo !== 'SUCCESS' && showBtmProtocollo" :pathResource="pathResourceSend" :id="item.id" size="sm" @update="onUpdateLista"></protocollo-component>
              <b-button size="sm" class="no-text" variant="outline-danger" @click="onAddIndagineEpidemiologica(item)" v-if="createIndagineEpidemiologica">
                <b-icon icon="plus"></b-icon>
                Aggiungi Indagine
              </b-button>
              <print-component :jsonData="item" :linkPrintData="linkPrintData(item)" typeButton="small" :firmaGrafometrica="false"></print-component>
            </div>
            <div v-else>
              <b-input-group>
                <protocollo-component v-if="item.statoProtocollo !== 'SUCCESS' && showBtmProtocollo" :data="item" :pathResource="pathResourceSend" size="sm" @update="onUpdateLista"></protocollo-component>
                <b-button size="sm" class="no-text" variant="outline-success" @click="onEditIndagineEpidemiologica(item)" v-if="editIndagineEpidemiologica">
                  <b-icon icon="pencil"></b-icon>
                  Modifica Indagine
                </b-button>
                <b-button size="sm" class="mr-1 ml-1 no-text" variant="outline-secondary" @click="onClickBtnVisualizzaIndagine(item)" v-if="showIndagineEpidemiologica">
                  <font-awesome-icon icon="eye" />
                </b-button>
              </b-input-group>
            </div>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
      <b-modal ref="mdlCambiaStato" id="mdlCambiaStato">
        <template #modal-title>{{ titleModal }}</template>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <span class="sa-data">{{ getLabelTraduora("adi.lblState") }}</span>
            <b-form-select v-model="nuovoStato.stato" :options="statiOptions" id="stato" value-field="value" text-field="text"></b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <span class="sa-data">{{ getLabelTraduora("adi.lblNote") }}</span>
            <b-form-textarea v-model="nuovoStato.note" no-resize type="search" id="notestato" rows="4"></b-form-textarea>
          </b-col>
        </b-row>
        <template #modal-footer="{ cancel }">
          <b-button size="sm" variant="outline-danger" @click="cancel()">Cancel</b-button>
          <b-button size="sm" variant="outline-success" @click="cambiaStato()">{{ titleModal }}</b-button>
        </template>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import PrintComponent from "../../../app/utility/components/PrintComponent.vue";
import ProtocolloComponent from "../../protocollo/components/ProtocolloComponent.vue";
export default {
  props: {
    utente: { type: Object },
    pathResource: String,
    fonte: String,
  },
  mixins: [UtilityMixin],
  components: { SaPageLayout, PrintComponent, ProtocolloComponent },
  mounted() {
    let me = this;
    me.linkedit = "/malattieinfettive" + me.fonte;
    me.id = this.$route.params.id;
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
    }
    me.loadData();
    me.loadStatiMalattia();
    me.impostaPermessi();
  },
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  data() {
    return {
      id: "-1",
      linkedit: null,
      btnNewVisible: false,
      showIndagineEpidemiologica: false,
      editIndagineEpidemiologica: false,
      createIndagineEpidemiologica: false,
      showBtmProtocollo: false,
      showStrongVaccino: true,
      pathResourceStatiMalattia: "/malattieinfettivestatimalattia",
      pathResourceCambioStatoMalattia: "/malattieinfettivesegnalazionestatimalattia/cambiastato",
      linkIndagineEpidemiologica: "/malattieinfettive/archivio/indagineepidemiologica",
      pathResourceSend: "/malattieinfettivesegnalazioni/sendprotocol",
      items: [],
      nuovoStato: {
        stato: null,
        note: "",
        idMalattiaInfettivaSegnalazione: null,
      },
      titleModal: this.$i18n.t("patient.infectiousDiseases.lblChangeState"),
      statiOptions: [],
      fields: [
        {
          label: "Codice Fiscale",
          key: "anagrafica",
          thStyle: "width: 10rem",
          sortable: true,
        },
        {
          label: "Malattia Segnalata",
          key: "malattiaSegnalata",
          sortable: true,
        },
        {
          label: "Vaccino",
          key: "vaccino",
          sortable: true,
          thStyle: "width: 10rem",
        },
        {
          label: "Data Segnalazione",
          key: "dataSegnalazione",
          sortable: true,
          thStyle: "width: 10rem",
          formatter: function (value) {
            return UtilityMixin.methods.formatDate(value);
          },
        },
        {
          label: "Stato Protocollo",
          key: "statoProtocollo",
          sortable: true,
          thStyle: "width: 10rem",
        },
        {
          label: "N. Protocollo",
          key: "numeroProtocollo",
          sortable: true,
          thStyle: "width: 10rem",
        },
        {
          label: "Data Protocollo",
          key: "dataProtocollo",
          sortable: true,
          thStyle: "width: 18rem",
          formatter: function (value) {
            return UtilityMixin.methods.formatDateTime(value);
          },
        },
        // {
        //     label: "Stato",
        //     key: "statoAttuale",
        //     thStyle: "width: 3rem",
        //     sortable: true,
        // },
        {
          label: "",
          tdClass: "text-center",
          thStyle: "width: 20rem",
          key: "actions",
        },
      ],
      filter: {},
      perPage: 50,
      rows: 0,
      currentPage: 1,
      filtro: {
        codiceFiscale: "",
        malattiaSegnalata: "",
      },

      showModalLoading: false,
    };
  },
  computed: {
    // rows() {
    //     return this.items.length;
    // },
  },

  methods: {
    linkPrintData(item) {
      let returnValue = "/malattieinfettivesegnalazioni/printer/" + item.id;
      return returnValue;
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?forPage=" + me.perPage + "&page=" + me.currentPage;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.rows = response.data.data.recordsNumber;
          me.items = response.data.data.list;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    loadStatiMalattia() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceStatiMalattia + "?forPage=" + me.perPage + "&page=" + me.currentPage;
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.statiOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.statiOptions.unshift({ value: null, text: this.getLabelTraduora("patient.diseaseInfection.lblSelection") });
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onCambiaStato(item) {
      let me = this;
      me.nuovoStato.stato = item.statoAttuale;
      me.nuovoStato.idMalattiaInfettivaSegnalazione = item.id;
    },
    cambiaStato() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceCambioStatoMalattia;
      axios
        .post(link, me.nuovoStato)
        .then((response) => {
          this.$refs["mdlCambiaStato"].hide();
          this.$bvToast.toast(` ${this.getLabelTraduora("patient.diseaseInfection.lblChangeState") + response.data.data.statoAttuale} `, {
            title: this.titleModal,
            variant: "success",
            autoHideDelay: 1000,
          });
          me.loadData();
        })
        .catch(() => {});
      axios;
    },
    onAddIndagineEpidemiologica(value) {
      let me = this;
      let link = me.linkIndagineEpidemiologica + "/edit/" + value.id + "?idPaziente=" + value.idAnagrafica + "&indagineEpidemiologicaComponent=" + value.indagineEpidemiologica;
      // let link = me.linkIndagineEpidemiologica + value.indagineEpidemiologica + "/edit/-1?idPaziente=" + value.idAnagrafica + "&indagineEpidemiologica=" + value.indagineEpidemiologica;
      me.$router.replace(link).catch((err) => {
        err;
      });
    },
    onEditIndagineEpidemiologica(value) {
      let me = this;
      me.$router.replace(me.linkIndagineEpidemiologica + "/edit/" + value.id + "?idPaziente=" + value.idAnagrafica + "&indagineEpidemiologicaComponent=" + value.indagineEpidemiologica).catch((err) => {
        // me.$router.replace(me.linkIndagineEpidemiologica + value.indagineEpidemiologica + "/edit/" + value.id + "?idPaziente=" + value.idAnagrafica + "&indagineEpidemiologica=" + value.indagineEpidemiologica).catch((err) => {
        err;
      });
    },
    onClickBtnVisualizzaIndagine(value) {
      let me = this;
      me.$router.replace(me.linkIndagineEpidemiologica + "/view/" + value.id + "?idPaziente=" + value.idAnagrafica + "&indagineEpidemiologicaComponent=" + value.indagineEpidemiologica).catch((err) => {
        // me.$router.replace(me.linkIndagineEpidemiologica + value.indagineEpidemiologica + "/view/" + value.id + "?idPaziente=" + value.idAnagrafica).catch((err) => {
        err;
      });
    },
    getVariantBadge(value) {
      let variant = "primary";
      switch (value) {
        case "GUARITO":
          variant = "success";
          break;
        case "ATTIVA":
          variant = "danger";
          break;
        case "MORTE":
          variant = "dark";
          break;

        default:
          break;
      }

      return variant;
    },
    getClassColorVaccino(value) {
      let variant = "primary";
      switch (value) {
        case "VACCINATO":
          variant = "text-success";
          break;
        case "NON VACCINATO":
          variant = "text-danger";
          break;
        case "NON NOTO":
          variant = "text-warning";
          break;
        case "NON ESISTE VACCINO":
          variant = "text-primary";
          break;

        default:
          break;
      }

      return variant;
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {
        codiceFiscale: "",
        malattia: "",
      };
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    getCodiceFiscale(row) {
      let me = this;
      me.filtro.codiceFiscale = row[0].identificativo;
      me.loadData();
    },
    impostaPermessi() {
      let me = this;
      if (me.fonte === "/segnalazioni") {
        me.btnNewVisible = UtilityMixin.methods.verificaPermessi(me.utente, "malattieinfettive", 2);
      }
      me.createIndagineEpidemiologica = UtilityMixin.methods.verificaPermessi(me.utente, "indagineepidemiologica", 2);
      me.editIndagineEpidemiologica = UtilityMixin.methods.verificaPermessi(me.utente, "indagineepidemiologica", 4);
      me.showIndagineEpidemiologica = UtilityMixin.methods.verificaPermessi(me.utente, "indagineepidemiologica", 1);
      me.showBtmProtocollo = UtilityMixin.methods.verificaPermessi(me.utente, "protocol", 2);
    },
    onUpdateLista() {
      let me = this;
      me.loadData();
    },
  },
};
</script>

<style></style>
