<template>
  <div>
    <div class="sa-list-component-header">
      <b-row>
        <b-col class="sa-padding-right text-right">
          <b-button variant="outline-info" @click="onTblRefresh">
            <b-icon icon="arrow-clockwise" />
            Aggiorna
          </b-button>
        </b-col>
      </b-row>
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
    <div class="sa-list-component-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <template #cell(urlDocumento)="item">
            <a v-bind:href="item.item.urlDocumento"> Scarica </a>
          </template>
          <template #cell(urlDocumentoTimbrato)="item">
            <a v-bind:href="item.item.urlDocumentoTimbrato"> Scarica </a>
          </template>
          <template #cell(statoAttualeDataOra)="item">
            {{ formatDateTime(item.item.statoAttualeDataOra) }}
          </template>
        </b-table>
      </div>
    </div>
    <div class="sa-list-component-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: {
    idDocumento: {
      type: String,
    },
  },
  data() {
    return {
      rows: 0,
      perPage: 50,
      currentPage: 1,
      id: null,
      pathResource: "/protocolresponse",
      filtro: { idDocumento: "" },
      items: [],
      listKey: 0,
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Esito Invocazione",
          key: "statoAttuale",
          sortable: true,
        },

        {
          label: "Cod Protocollo",
          key: "idProtocollo",
          sortable: true,
        },
        {
          label: "Numero Protocollo",
          key: "numeroProtocollo",
          sortable: true,
        },
        {
          label: "Documento Inviato",
          key: "urlDocumento",
          sortable: true,
        },
        {
          label: "Documento Protocollato",
          key: "urlDocumentoTimbrato",
          sortable: true,
        },
        {
          label: "Data Ora",
          key: "statoAttualeDataOra",
          sortable: true,
          thStyle: "width: 10rem",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      me.filtro.idDocumento = me.id;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          me.listKey++;
          //          me.changeGestore();
          // this.$emit("afterLoadData");
        })
        .catch(() => {
          // this.$emit("afterLoadData");
        });
    },
    onTblRefresh() {
      let me = this;
      me.loadData();
    },
  },
};
</script>

<style></style>
