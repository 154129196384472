<template>
  <div class="sa-tab-scroll">
    <!-- <protocollo-component v-if="jsonData.statoProtocollo !== 'SUCCESS' && showBtmProtocollo" :id="jsonData.id" :pathResource="pathResourceSend" size="md"></protocollo-component> -->
    <b-card class="sa-card" header="Informazioni Anagrafiche relative al caso" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Tipo Caso:</label>
          <span class="sa-data">{{ jsonData.tipoCaso }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Installata App Immuni:</label>
          <span class="sa-data">{{ jsonData.appImmuniAttiva }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Operatore Sanitario:</label>
          <span class="sa-data">{{ jsonData.operatoreSanitario }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sede:</label>
          <span class="sa-data">{{ jsonData.operatoreSanitarioSede }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Telefono:</label>
          <span class="sa-data">{{ jsonData.operatoreSanitarioSedeTelefono }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Composizione del Nucleo abitativo" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaFamiliari" :fields="fieldsFamiliari" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
        <template #cell(index)="jsonData">
          {{ jsonData.index + 1 }}
        </template>
      </b-table>
    </b-card>
    <b-card class="sa-card" header="Informazioni Cliniche e di Laboratorio" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Sintomi:</label>
          <span class="sa-data">{{ jsonData.tipoSintomi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Sintomi:</label>
          <span class="sa-data">{{ formatDate(jsonData.dataInsorgenzaSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sintomi Simil-Influenzali:</label>
          <span class="sa-data">{{ jsonData.sintomatologiaSimileInfluenza }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Febbre (>37.5):</label>
          <span class="sa-data">{{ jsonData.febbre }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tosse:</label>
          <span class="sa-data">{{ jsonData.tosse }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Difficolta Respiratoria:</label>
          <span class="sa-data">{{ jsonData.difficoltaRespiratoria }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Mal di Gola:</label>
          <span class="sa-data">{{ jsonData.malGola }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altro:</label>
          <span class="sa-data">{{ jsonData.altro }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Condizioni Cliniche Gravi:</label>
          <span class="sa-data">{{ jsonData.condizioniClinicheGravi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ricovero Terapia Intensiva:</label>
          <span class="sa-data">{{ jsonData.ricoveroTerapiaIntensiva }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ospedale:</label>
          <span class="sa-data">{{ jsonData.luogoRicovero }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data:</label>
          <span class="sa-data">{{ formatDate(jsonData.dataRicovero) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Trasferito da Altro Ospedale:</label>
          <span class="sa-data">{{ jsonData.trasferitoAltroOspedale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Struttura Provenienza:</label>
          <span class="sa-data">{{ jsonData.trasferitoAltroOspedaleStrutturaProvenienza }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vaccino influenza 2020/21:</label>
          <span class="sa-data">{{ jsonData.vaccinoInfluenzaDuemilaventiVentuno }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Mese:</label>
          <span class="sa-data">{{ jsonData.vaccinoInfluenzaDuemilaventiVentunoMese }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Anno:</label>
          <span class="sa-data">{{ jsonData.vaccinoInfluenzaDuemilaventiVentunoAnno }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Isolamento Domiciliare Fiduciario:</label>
          <span class="sa-data">{{ jsonData.isolamentoDomiciliareFiduciario }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Dove:</label>
          <span class="sa-data">{{ jsonData.isolamentoDomiciliareFiduciarioDove }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Esami Laboratorio SARS-Cov-2 Effettuati" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Prima Indagine Tipo:</label>
          <span class="sa-data">{{ jsonData.primaIndagineTipo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Prima Indagine Data:</label>
          <span class="sa-data">{{ formatDate(jsonData.primaIndagineDataPrelievo) }}</span>
          <!-- <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.primaIndagineTipo" :options="tipoIndagineOptions" value="" value-field="value" text-field="text"></b-form-select> -->
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Prima Indagine Esito:</label>
          <span class="sa-data">{{ jsonData.primaIndagineEsito }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Seconda Indagine Tipo:</label>
          <span class="sa-data">{{ jsonData.secondaIndagineTipo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Seconda Indagine Data:</label>
          <span class="sa-data">{{ formatDate(jsonData.secondaIndagineDataPrelievo) }}</span>
          <!-- <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.primaIndagineTipo" :options="tipoIndagineOptions" value="" value-field="value" text-field="text"></b-form-select> -->
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Seconda Indagine Esito:</label>
          <span class="sa-data">{{ jsonData.secondaIndagineEsito }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Epidemiologici" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Contatto Caso Confermato:</label>
          <span class="sa-data">{{ jsonData.contattoCasoConfermato }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nominativo:</label>
          <span class="sa-data">{{ jsonData.contattoCasoConfermatoNominativo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Telefono:</label>
          <span class="sa-data">{{ jsonData.contattoCasoConfermatoContatto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Contatto:</label>
          <span class="sa-data">{{ formatDate(jsonData.contattoCasoConfermatoData) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Presente in area con casi confermati:</label>
          <span class="sa-data">{{ jsonData.presenzaAreaRischio }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nazione:</label>
          <span class="sa-data">{{ jsonData.presenzaAreaRischioNazione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Provincia:</label>
          <span class="sa-data">{{ jsonData.presenzaAreaRischioProvincia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Citta:</label>
          <span class="sa-data">{{ jsonData.presenzaAreaRischioCitta }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Arrivo:</label>
          <span class="sa-data">{{ formatDate(jsonData.presenzaAreaRischioDataArrivo) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Partenza:</label>
          <span class="sa-data">{{ formatDate(jsonData.presenzaAreaRischioDataRientro) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Provincia Rientro:</label>
          <span class="sa-data">{{ jsonData.presenzaAreaRischioLuogoRientroProvincia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Comune Rientro:</label>
          <span class="sa-data">{{ jsonData.presenzaAreaRischioLuogoRientroDescrizione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Mezzo di Trasporto:</label>
          <span class="sa-data">{{ jsonData.presenzaAreaRischioRientroMezzoTrasporto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Ora:</label>
          <span class="sa-data">{{ formatDate(jsonData.presenzaAreaRischioRientroMezzoTrasportoData) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Compagnia:</label>
          <span class="sa-data">{{ jsonData.presenzaAreaRischioRientroMezzoTrasportoCompagnia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Id Viaggio:</label>
          <span class="sa-data">{{ jsonData.presenzaAreaRischioRientroMezzoTrasportoIdViaggio }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Numero Posto:</label>
          <span class="sa-data">{{ jsonData.presenzaAreaRischioRientroMezzoTrasportoPosto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Altro Tipo Trasporto:</label>
          <span class="sa-data">{{ jsonData.presenzaAreaRischioRientroMezzoTrasportoAltro }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Spostamento Mezzi Pubblici/Privati:</label>
          <span class="sa-data">{{ jsonData.spostamentoMezziPubbliciPrivati }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Dettaglio Mezzi Pubblici/Privati:</label>
          <span class="sa-data">{{ jsonData.spostamentoMezziPubbliciDettagli }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Viaggi Gruppo/Organizzati Italia:</label>
          <span class="sa-data">{{ jsonData.partecipatoViaggiGruppoItalia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tour Operator:</label>
          <span class="sa-data">{{ jsonData.partecipatoViaggiGruppoItaliaTourOperator }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Numero Viaggiatori:</label>
          <span class="sa-data">{{ jsonData.partecipatoViaggiGruppoItaliaNumeroViaggiatori }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dettaglio Soggiorno Citta' Italiane" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaViaggiItalia" :fields="fieldsCitta" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
        <template #cell(index)="jsonData">
          {{ jsonData.index + 1 }}
        </template>
        <template #cell(provinciaDescrizione)="item">
          <span class="sa-data">{{ item.item.provinciaDescrizione }}</span>
        </template>
        <template #cell(citta)="item">
          <span class="sa-data">{{ item.item.cittaDescrizione }}</span>
        </template>
        <template #cell(dataArrivo)="item">
          <span class="sa-data">{{ formatDate(item.item.dataArrivo) }}</span>
        </template>
        <template #cell(dataPartenza)="item">
          <span class="sa-data">{{ formatDate(item.item.dataPartenza) }}</span>
        </template>
        <!-- <template #cell(actions)="row">
          <b-button v-if="row.index == jsonData.listaViaggiItalia.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddCitta(jsonData.listaViaggiItalia, row.item)">
            <b-icon icon="plus"></b-icon>
          </b-button>
          <b-button v-if="row.index !== jsonData.listaViaggiItalia.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteCitta(jsonData.listaViaggiItalia, row)">
            <b-icon icon="trash"></b-icon>
          </b-button>
        </template> -->
      </b-table>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Partecipato Meeting/Convegni/Raduni:</label>
          <span class="sa-data">{{ jsonData.partecipatoMeetingConvegniRaduni }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Dal:</label>
          <span class="sa-data">{{ formatDate(jsonData.partecipatoMeetingConvegniRaduniDal) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Al:</label>
          <span class="sa-data">{{ formatDate(jsonData.partecipatoMeetingConvegniRaduniAl) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Luogo:</label>
          <span class="sa-data">{{ jsonData.partecipatoViaggiGruppoItaliaNumeroViaggiatori }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Quattordici giorni precedenti" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Lavoro/Frequenza Struttura Sanitaria:</label>
          <span class="sa-data">{{ jsonData.contattoQuattordiciGiorniPrima }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nome Struttura:</label>
          <span class="sa-data">{{ jsonData.contattoQuattordiciGiorniPrimaNomeStruttura }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Recapito:</label>
          <span class="sa-data">{{ jsonData.contattoQuattordiciGiorniPrimaNomeStruttura }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Lavoro/Frequenza Struttura Sanitaria:</label>
          <span class="sa-data">{{ jsonData.contattoProvenienteZoneRischio }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Follow Up" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Esito:</label>
          <span class="sa-data">{{ jsonData.followupEsito }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data:</label>
          <span class="sa-data">{{ formatDate(jsonData.followupEsitoData) }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Contact tracing - Lista contatti stretti" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
        <template #cell(index)="jsonData">
          {{ jsonData.index + 1 }}
        </template>
        <template #cell(dataContatto)="item">
          <span class="sa-data">{{ formatDate(item.item.dataContatto) }}</span>
        </template>
        <!-- <template #cell(actions)="row">
          <b-button v-if="row.index == jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddContatto(jsonData.listaContatti, row.item)">
            <b-icon icon="plus"></b-icon>
          </b-button>
          <b-button v-if="row.index !== jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteContatto(jsonData.listaContatti, row)">
            <b-icon icon="trash"></b-icon>
          </b-button> -->
        <!-- </template> -->
      </b-table>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Note:</label>
          <span class="sa-data">{{ jsonData.note }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
      <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="false" @update="onUpdateJsonData" :disabled="chekCondition(jsonData.ricoveroTerapiaIntensiva, 'SI')"></indagine-epidemiologica-dati-notifica-component>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import IndagineEpidemiologicaDatiNotificaComponent from "../components/IndagineEpidemiologicaDatiNotificaComponent.vue";

export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
      currentProperties: { type: Object },
    },
  },
  components: { IndagineEpidemiologicaDatiNotificaComponent }, //, DatePicker
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveiecovid",
      linkback: null,
      currentPage: 1,
      perPage: 100,
      showModalLoading: false,
      disableSintomi: true,
      jsonData: {
        tipoCaso: "",
        appImmuniAttiva: "",
        operatoreSanitario: "",
        operatoreSanitarioSede: "",
        operatoreSanitarioSedeTelefono: "",
        tipoSintomi: "",
        dataInsorgenzaSintomi: null,
        sintomatologiaSimileInfluenza: "",
        febbre: "",
        tosse: "",
        difficoltaRespiratoria: "",
        malGola: "",
        altro: "",
        condizioniClinicheGravi: "",
        ricoveroTerapiaIntensiva: "",
        luogoRicovero: "",
        dataRicovero: null,
        trasferitoAltroOspedale: "",
        trasferitoAltroOspedaleStrutturaProvenienza: "",
        vaccinoInfluenzaDuemilaventiVentuno: "",
        vaccinoInfluenzaDuemilaventiVentunoMese: "",
        vaccinoInfluenzaDuemilaventiVentunoAnno: "",
        isolamentoDomiciliareFiduciario: "",
        isolamentoDomiciliareFiduciarioDove: "",
        primaIndagineTipo: "",
        primaIndagineDataPrelievo: null,
        primaIndagineEsito: "",
        secondaIndagineTipo: "",
        secondaIndagioneDataPrelievo: null,
        secondaIndagineEsito: "",
        contattoCasoConfermato: "",
        contattoCasoConfermatoNominativo: "",
        contattoCasoConfermatoContatto: "",
        contattoCasoConfermatoData: null,
        presenzaAreaRischio: "",
        presenzaAreaRischioNazione: "",
        presenzaAreaRischioCitta: "",
        presenzaAreaRischioProvincia: "",
        presenzaAreaRischioDataArrivo: null,
        presenzaAreaRischioDataRientro: null,
        presenzaAreaRischioLuogoRientro: "",
        presenzaAreaRischioProvinciaRientro: "",
        presenzaAreaRischioRientroMezzoTrasporto: "",
        presenzaAreaRischioRientroMezzoTrasportoData: null,
        presenzaAreaRischioRientroMezzoTrasportoCompagnia: "",
        presenzaAreaRischioRientroMezzoTrasportoIdViaggio: "",
        presenzaAreaRischioRientroMezzoTrasportoPosto: "",
        presenzaAreaRischioRientroMezzoTrasportoAltro: "",
        spostamentoMezziPubbliciPrivati: "",
        spostamentoMezziPubbliciDettagli: "",
        partecipatoViaggiGruppoItalia: "",
        partecipatoViaggiGruppoItaliaTourOperator: "",
        partecipatoViaggiGruppoItaliaNumeroViaggiatori: "",
        partecipatoMeetingConvegniRaduni: "",
        partecipatoMeetingConvegniRaduniDal: null,
        partecipatoMeetingConvegniRaduniAl: null,
        partecipatoMeetingConvegniRaduniLuogo: "",
        contattoQuattordiciGiorniPrima: "",
        contattoQuattordiciGiorniPrimaNomeStruttura: "",
        contattoQuattordiciGiorniPrimaRecapiti: "",
        contattoProvenienteZoneRischio: "",
        followupEsito: "",
        followupEsitoData: null,
        note: "",
        sanitarioNotificante: "",
        dataNotifica: null,
        sanitarioNotificanteContatto: "",
        aslAppartenenza: "",
        emailNotificante: "",
        idAnagrafica: "",
        presenzaAreaRischioLuogoRientroProvincia: "",
        listaContatti: [],
        listaFamiliari: [],
        listaViaggiItalia: [],
      },
      fieldsFamiliari: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Cognome Nome",
          key: "cognomeNome",
          sortable: false,
        },
        {
          label: "Grado Parentela",
          key: "gradoParentela",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Eta",
          key: "eta",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Occupazione",
          key: "occupazione",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      fieldsCitta: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Provincia",
          key: "provinciaDescrizione",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Citta",
          key: "citta",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Alloggio",
          key: "alloggio",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Telefono",
          key: "telefono",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Data Arrivo",
          key: "dataArrivo",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Data Partenza",
          key: "dataPartenza",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      fieldsContatti: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Data Contatto",
          key: "dataContatto",
          sortable: false,
          thStyle: "width: 15rem",
        },
        {
          label: "Cognome",
          key: "cognome",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Nome",
          key: "nome",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Telefono",
          key: "telefono",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Comune",
          key: "comune",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      nazioniOptions: [],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.linkback = "/malattieinfettive/segnalazioni";

    me.loadData();
  },
  watch: {},
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    // getProtocolParams() {
    //   let me = this;
    //   return { id: me.id, pathResource: me.pathResourceSend };
    // },
  },
};
</script>
