<template>
    <div class="sa-tab-scroll">
        <b-row style="height: 80%">
            <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="" :disabled="isDisalble" style="height: 100%">
                    <!-- <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"> -->
                    <iframe allow="camera;microphone" :src="linkPacs" width="100%" height="100%" frameborder="0"></iframe>
                    <!-- </b-col>
          </b-row> -->
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-button variant="outline-info  sa-margin-right float-sm-right" size="md" @click="onSendPacs" :disabled="isDisalble">
                            <!-- <b-button variant="btn-toolbar sa-margin-right float-sm-right" size="md" v-b-modal.mdlSpirometria> -->
                            <i class="fas fa-share-square"></i>
                            Invia Pacs
                        </b-button>
                    </b-col>
                </b-row>
                <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">{{ this.getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                            <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime" disabled></date-picker>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="CONCLUSIONI" header-tag="header" footer-tag="footer" title="" :disabled="isDisalble">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <!-- <label class="sa-label-data">Conclusioni</label> -->
                            <b-form-textarea v-model="jsonData.conclusioni" type="text" rows="9" no-resize placeholder="Conclusioni" :disabled="isDisalble"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                    <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col lg="3">
                            <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                        </b-col>
                        <b-col lg="9">
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                        </b-col>
                    </b-row>
                    <b-table sticky-header ref="table" stacked="xl" striped hover :items="listaRichieste" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" no-header>
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template v-slot:cell(actions)="row">
                            <b-button style="margin-right: 5px" variant="outline-success" @click="onVisualizza(row.item.id)">
                                <i class="fas fa-eye"></i>
                            </b-button>
                            <b-button style="margin-right: 5px" variant="outline-danger" @click="onClose()">
                                <i class="fas fa-window-close"></i>
                            </b-button>
                        </template>
                    </b-table>
                    <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col lg="3">
                            <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                        </b-col>
                        <b-col lg="9">
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <!-- <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"> -->

        <!-- </b-col>
    </b-row> -->
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
    components: { DatePicker },
    mixins: [UtilityMixin],

    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    conclusioni: "",
                    dataEvento: new Date().getTime(),
                };
            },
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.dataEvento = new Date().getTime();
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        currentPage() {
            let me = this;
            me.loadDatiPacs();
        },
    },
    data() {
        return {
            rows: 0,
            currentPage: 1,
            perPage: 10,
            pathResource: "/ecgvisite",
            pathPacs: "/pacsenergy/sendpacs/",
            pathPacsListaResponse: "/pacsenergyresponse",
            pathPacsEndpoint: "/pacsenergy/endpoint/",
            isDisalble: false,
            linkPacs: "",
            id: "-1",
            idRisorsa: null,
            disableCampi: false,
            listaRichieste: [],
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Data Ora",
                    key: "dataOraResponse",
                    formatter: function (value) {
                        return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
                    },
                },
                { class: "sa-table-column-action-single", key: "actions", label: "", thStyle: "width: 8rem" },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            this.$emit("beforeLoadData");
            if (me.id === "-1") {
                this.$emit("afterLoadData");
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.loadDatiPacs();
                    this.$emit("afterLoadData");
                });
            }
        },

        onSendPacs() {
            let me = this;
            this.$emit("beforeLoadData");
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            if (me.id === "-1" || me.id === null || me.id === undefined) {
                axios
                    .post(link, JSON.stringify(me.jsonData))
                    .then((response) => {
                        this.$emit("update", response.data.data);
                        me.id = response.data.data.id;
                        this.$emit("afterLoadData");
                        me.sendPacs(response.data.data.id);
                        me.$router.replace("/paziente/publipeas/visitaecg/edit/" + response.data.data.id + "?idPaziente=" + me.idPaziente).catch((err) => {
                            err;
                        });

                        me.loadData();
                    })
                    .catch((error) => {
                        this.$emit("afterLoadData");
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                link = link + "/" + me.jsonData.id;
                axios
                    .put(link, JSON.stringify(me.jsonData))
                    .then((response) => {
                        me.sendPacs(response.data.data.id);
                        this.$emit("update", response.data.data);
                    })
                    .catch((error) => {
                        this.$emit("afterLoadData");
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                console.log(value);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        sendPacs(value) {
            // console.log(value);
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathPacs + value;
            axios
                .get(link)
                .then(() => {
                    me.isDisalble = true;
                    me.loadDatiPacs();
                    this.$emit("afterLoadData");
                })
                .catch((error) => {
                    this.$emit("afterLoadData");
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            console.log(value);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        loadDatiPacs() {
            let me = this;
            let filtro = { idEvento: me.id, page: me.currentPage, forPage: me.perPage };
            let link = process.env.VUE_APP_PATH_API + me.pathPacsListaResponse;
            axios
                .get(link, { params: filtro })
                .then((response) => {
                    me.listaRichieste = [];
                    me.listaRichieste = response.data.data.list;
                    me.rows = response.data.data.recordsNumber;
                })
                .catch((error) => {
                    this.$emit("afterLoadData");
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            console.log(value);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onUpdateBase64() {},
        onUpdateLista(data) {
            let me = this;
            me.listaRichieste = data;
            if (me.listaRichieste.length > 0) {
                me.disableCampi = true;
                if (me.listaRichieste[0].statoAttuale !== "IN VISITA") {
                    me.disableCampi = false;
                }
            }
        },
        onVisualizza(id) {
            let me = this;
            me.isDisalble = false;
            let link = process.env.VUE_APP_PATH_API + me.pathPacsEndpoint + id;
            axios
                .get(link)
                .then((response) => {
                    me.linkPacs = "";
                    me.linkPacs = response.data.data.url;
                    // console.log(me.linkPacs);
                })
                .catch((error) => {
                    this.$emit("afterLoadData");
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            console.log(value);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onClose() {
            let me = this;
            me.linkPacs = "";
        },
    },
};
</script>

<style></style>
