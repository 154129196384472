<template>
  <sa-page-layout :data="jsonData" :btnEditVisible="true" :linkedit="linkedit" :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :showModalLoading="showModalLoading" class="sa-no-space">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar">
      <protocollo-component v-if="btnProtocolDisabled" :pathResource="pathResourceSend" :tipoDocumento="'INDAGINE'" size="sm"></protocollo-component>
    </template>
    <template slot="table-header">
      <h3 class="sa-event-title">Malattia Segnalata: {{ malattiaInfettivaSegnalata }}</h3>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <component ref="indagineComponent" :is="currentComponentView" v-bind="currentProperties"></component>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="margin-top: -20px">
            <b-card class="sa-card" header="Protocollo" header-tag="header" footer-tag="footer" title="">
              <protocollo-list-component :idDocumento="jsonData.id"></protocollo-list-component>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../paziente/components/PazienteAnagrafica.vue";
import ProtocolloComponent from "../../../protocollo/components/ProtocolloComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import ProtocolloListComponent from "../../../protocollo/components/ProtocolloListComponent.vue";
import axios from "axios";
import indagini from "../components";
import Vue from "vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: {
    SaPageLayout,
    PazienteAnagrafica,
    ProtocolloComponent,
    ProtocolloListComponent,
  },
  data() {
    return {
      pathResource: "",

      showModalLoading: false,
      linkedit: null,
      id: "-1",
      pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
      linkback: "/malattieinfettive/segnalazioni",
      pathResourceSend: "/malattieinfettiveie/sendprotocol",
      indagineEpidemiologicaParams: null,
      malattiaInfettivaSegnalata: "",
      indagineEpidemiologica: null,
      indagineEpidemiologicaComponent: null,
      jsonData: {},
      btnProtocolDisabled: false,
      currentComponentView: null,
      currentProperties: {},
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.indagineEpidemiologica = this.$route.query.indagineEpidemiologica;
    me.indagineEpidemiologicaComponent = this.$route.query.indagineEpidemiologicaComponent;
    me.setMalattiaSegnalata();
    me.currentComponentView = indagini.viewComponents()[me.indagineEpidemiologicaComponent + "ViewComponent.vue"];
    me.impostaPermessi();
    me.linkedit = "/malattieinfettive/archivio/indagineepidemiologica/edit/" + me.id + "?idPaziente=" + me.idPaziente + "&indagineEpidemiologicaComponent=" + me.indagineEpidemiologicaComponent;
  },
  methods: {
    onShowModalLoading(value) {
      let me = this;
      me.showModalLoading = value;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    setMalattiaSegnalata() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.malattiaInfettivaSegnalata = response.data.data.malattiaSegnalata;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    impostaPermessi() {
      let me = this;
      me.btnProtocolDisabled = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "protocol", 2);
    },
  },
};
</script>
